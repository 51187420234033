<template>
  <div class="page not-found">
    <h1>404</h1>
    <h3>Page Not Found</h3>
  </div>
</template>
<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  justify-content: center;
  align-items: center;
}
</style>
